<template>
  <div>
    <nav-bar title="公告详情"></nav-bar>
    <div class="gonggao">
      <span class="title">{{ list.title }}</span>
      <span class="time">{{ list.create_at }}</span>
      <span class="line"></span>
      <span class="name">{{ list.content }}</span>
      <div class="message"></div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.gettongzhi();
  },
  data() {
    return {
      id: this.$route.query.id,
      list: {},
    };
  },
  methods: {
    gettongzhi() {
      this.$post2("Article/Api/Index/getArticle", {
        id: this.id,
      }).then((res) => {
        console.log(res);
        this.list = res;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.line {
  width: 100%;
  margin-top: 0.3rem;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.gonggao {
  display: flex;
  width: 100%;
  flex-direction: column;
  .title {
    margin-top: 0.3rem;
    margin-left: 0.4rem;
    font-size: 0.32rem;
  }
  .time {
    font-size: 0.3rem;
    margin-top: 2px;
    margin-left: 0.4rem;
  }
  .name {
    font-size: 0.3rem;
    margin-top: 0.5rem;
    margin-left: 0.4rem;
  }
  .message {
    width: 335px;
    margin: 0 auto;
    margin-top: 0.16rem;
    font-size: 0.28rem;
    color: #9a9a9a;
  }
}
</style>